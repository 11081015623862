import { atom, atomFamily } from 'recoil';

export const channelSelectedAtom = atom<number | null>({
  key: 'channelSelected',
  default: null,
});

export const onlineChannelAtom = atomFamily<{ online: boolean; lastConnection?: Date }, number>({
  key: 'onlineChannelAtom',
  default: {
    online: false,
  },
});

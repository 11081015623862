import { atom } from 'recoil';
import { License } from 'types/license.types';

export const licenseModelSearchTextAtom = atom({
  key: 'licenseModelSearchTextAtom',
  default: '',
});

export const normalizeLicenses = (licenses: License[]) =>
  licenses.sort((a, b) => a.name.localeCompare(b.name));

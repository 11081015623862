import React from 'react';
import { Box } from '@mui/material';
import { LicenseModelsList, EmptyLicenses, SearchLicenseModelsBar } from 'components/LicenseModel';
import { useFetch } from 'hooks';
import { License } from 'types/license.types';
import { asyncComponent } from 'HOCs';
import { licenseModelSearchTextAtom, normalizeLicenses } from 'atoms/license';
import { useRecoilValue } from 'recoil';
import { LicenseModelListLoading } from 'components/LicenseModel/LicenseModelsList';

function LicenseModelsPage() {
  const text = useRecoilValue(licenseModelSearchTextAtom);

  const { data: licenses } = useFetch<License[]>('/v1/licenses', {
    normalizeData: normalizeLicenses,
    params: {
      text,
    },
  });

  // itemsComputed to mount skeleton loading
  const itemsComputed:
    | License[]
    | {
        id: number;
        name?: string;
      }[] = React.useMemo(() => {
    if (!licenses) {
      return Array.from({ length: 5 }).map((x, id) => ({
        id,
      }));
    }
    return licenses;
  }, [licenses]);

  if (!licenses?.length) {
    return <EmptyLicenses />;
  }

  return (
    <Box
      sx={{
        mt: 2,
        ml: 2,
      }}
    >
      <SearchLicenseModelsBar />
      <LicenseModelsList licenses={itemsComputed as License[]} />
    </Box>
  );
}

export default asyncComponent(
  LicenseModelsPage,
  <Box
    sx={{
      mt: 2,
      ml: 2,
    }}
  >
    <SearchLicenseModelsBar />

    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}
      >
        <LicenseModelListLoading />
      </Box>
    </Box>
  </Box>
);

import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useTranslation } from 'react-i18next';
import { License } from 'types/license.types';
import { formatLicenseDescription } from 'helpers/formatLicenseDescription';
import { useFetch } from 'hooks';
import { normalizeLicenses } from 'atoms/license';

function SelectLicenseType({
  selectedLicenseId,
  setSelectedLicense,
  onChange,
  hasError,
  helperText,
}: {
  selectedLicenseId: number | null | undefined;
  setSelectedLicense: (license: License | null) => void;
  onChange: (value: SelectChangeEvent<License | null>) => void;
  hasError?: boolean;
  helperText?: string;
}) {
  const { t } = useTranslation();
  const { data: licenses } = useFetch<License[]>('/v1/licenses', {
    normalizeData: normalizeLicenses,
  });

  if (!licenses) {
    return <SelectLicenseType.Loading />;
  }

  if (!licenses.length) {
    return (
      <FormControl
        variant="outlined"
        fullWidth
        error={hasError}
        sx={{
          mb: '1.2rem',
          mt: '10px',
        }}
      >
        <InputLabel id="select-license-label-no-models">
          {t('license_models:license_type')}
        </InputLabel>
        <Select
          labelId="select-license-label-no-models"
          label={t('license_models:license_type')}
          value="0"
          disabled
        >
          <MenuItem value="0">{t('license_models:empty_licenses')}</MenuItem>
        </Select>
        <FormHelperText>{t('license_models:need_aditional_licenses')}</FormHelperText>
      </FormControl>
    );
  }

  const handleChange = (event: SelectChangeEvent<License | null>) => {
    const license = licenses.find((_license) => Number(_license.id) === Number(event.target.value));
    setSelectedLicense(license ?? null);
    onChange(event);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={hasError}
      sx={{
        mb: '1.2rem',
        mt: '10px',
      }}
    >
      <InputLabel id="select-license-label">{t('license_models:license_type')}</InputLabel>
      <Select
        labelId="select-license-label"
        id="licenseId"
        name="licenseId"
        label={t('license_models:license_type')}
        // @ts-ignore
        value={selectedLicenseId ?? ''}
        onChange={handleChange}
      >
        <MenuItem value="" />
        {licenses.map((license) => (
          <MenuItem key={license.id} id={String(license.id)} value={license.id}>
            {formatLicenseDescription(license.licenseType, t)}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectLicenseType.Loading = function SelectLoading() {
  const { t } = useTranslation();
  return <SelectFetchLoading label={t('license_models:license_type')} />;
};

export default SelectLicenseType;

import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  List,
  Divider,
  IconButton,
  Typography,
  Fab,
  Box,
  Theme,
  ListItemButton,
  ListItem,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  channelGroupInEditionAtom,
  channelGroupSearchTextAtom,
  channelGroupcreateModalOpenedAtom,
} from 'atoms/channelGroup';
import { Delete, Add, Edit } from '@mui/icons-material';
import { ConfirmModal } from 'components/core';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilState } from 'recoil';
import DebounceInput from 'components/core/DebounceInput';
import Skeleton from '@mui/material/Skeleton';
import { useHaveScroll } from 'hooks';
import Permissioned from 'components/Permissioned';
import { PermissionRole } from 'types/user.types';
import { ChannelGroup } from 'types/channelGroup.types';
import { useChannelGroupApi } from 'hooks/channelGroup/useChannelGroupApi';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    padding: '4px 0',
    flexDirection: 'initial',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      width: '30%',
    },
  },
  addButton: {
    marginLeft: 'auto',
  },
  containerList: {
    backgroundColor: theme.palette.background.default,
    flex: '0 0 100%',
    padding: '8px',
    overflow: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    overflow: 'auto',
    height: '100%',
    marginTop: '8px',
    padding: '0',
  },
}));

function ChannelGroupList(
  props: React.PropsWithChildren<{
    loading: true;
  }>
): React.ReactElement | null;
function ChannelGroupList(
  props: React.PropsWithChildren<{
    items: ChannelGroup[];
    selected: number;
    setSelected: React.Dispatch<React.SetStateAction<number>>;
  }>
): React.ReactElement | null;
function ChannelGroupList({
  items,
  selected,
  setSelected,
  loading,
}: {
  items?: ChannelGroup[];
  selected?: number;
  setSelected?: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation(['channel_groups', 'confirm_modal', 'channels']);

  const listaRef = useRef(null);
  const { haveScroll } = useHaveScroll(listaRef, [items]);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [idInDeletion, setIdInDeletion] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(channelGroupSearchTextAtom);
  const [searchText, setSearchText] = useState(searchTextDebounced);

  const { deleteChannelGroup } = useChannelGroupApi();
  const setChannelGroupInEdition = useSetRecoilState(channelGroupInEditionAtom);
  const setCreateModalOpened = useSetRecoilState(channelGroupcreateModalOpenedAtom);
  const inputRef = useRef<HTMLInputElement>();

  // itemsComputed to mount skeleton loading
  const itemsComputed: ChannelGroup[] = useMemo(() => {
    if (!items) {
      return Array.from({ length: 5 }).map((x, id) => ({
        id,
        name: '',
        quantity: 0,
        licenseId: null,
      }));
    }
    return items;
  }, [items]);

  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteChannelGroup(idInDeletion);
    setDeleteConfirmModalOpen(false);
    setIsDeleting(false);
  };

  const handlePutOnDeletion = (id: number) => {
    setIdInDeletion(id);
    setDeleteConfirmModalOpen(true);
  };

  const handleEdit = (channelGroup: ChannelGroup) => {
    setChannelGroupInEdition(channelGroup);
    setCreateModalOpened(true);
  };

  /* O foco no campo do search deve continuar mesmo após o loading aparecer na tela */
  useEffect(() => {
    inputRef.current?.focus();
  }, [items]);

  return (
    <Box className={classes.containerList}>
      <Box className={classes.header}>
        {(haveScroll || !!searchTextDebounced) && (
          <DebounceInput
            {...{ inputRef }}
            placeholder={t('crud_actions:search')}
            autoFocus
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            setValue={setSearchText}
            onDebounce={() => setSearchTextDebounced(searchText)}
          />
        )}
        <Permissioned role={PermissionRole.INSERT_CHANNEL_GROUP}>
          <Fab
            className={classes.addButton}
            color="primary"
            size="small"
            onClick={() => setCreateModalOpened(true)}
            aria-label="add"
          >
            <Add />
          </Fab>
        </Permissioned>
      </Box>
      <List
        ref={listaRef}
        className={classes.list}
        aria-label={loading ? 'loading' : 'Channel group list'}
      >
        {itemsComputed && itemsComputed.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography color="textSecondary">{t('crud_actions:no_results')}</Typography>
          </Box>
        ) : (
          itemsComputed.map((item) => (
            <span key={item.id}>
              <Divider />
              <ListItem aria-labelledby={`channel-group-list-item-${item.id}`}>
                <ListItemButton
                  sx={{ padding: '16px' }}
                  selected={selected === item.id}
                  onClick={() => setSelected && setSelected(item.id)}
                >
                  <Box flex="1 1 200px">
                    <Typography id={`channel-group-list-item-${item.id}`}>
                      {item.name || <Skeleton />}
                    </Typography>
                  </Box>
                  <Box flex="1 1 auto">
                    <Tooltip title={t('channels:qty_channels_linked')}>
                      <Typography
                        id={`channel-group-list-item-qnty-${item.id}`}
                        variant="caption"
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        {!!item.quantity &&
                          t('channels:linked_channel', {
                            count: item.quantity,
                          })}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Permissioned role={PermissionRole.EDIT_CHANNEL_GROUP}>
                    <Tooltip title={t('crud_actions:edit')}>
                      <IconButton
                        disabled={loading}
                        onClick={() => handleEdit(item as ChannelGroup)}
                        aria-label="edit"
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Permissioned>
                  <Permissioned role={PermissionRole.REMOVE_CHANNEL_GROUP}>
                    <Tooltip title={t('crud_actions:remove')}>
                      <IconButton
                        disabled={loading}
                        onClick={() => handlePutOnDeletion(item.id)}
                        aria-label="delete"
                        size="large"
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Permissioned>
                </ListItemButton>
              </ListItem>
            </span>
          ))
        )}
      </List>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        setOpen={setDeleteConfirmModalOpen}
        doConfirm={handleDelete}
        disabled={isDeleting}
        loading={isDeleting}
        confirmActionColor="primary"
        variant="contained"
        confirmActionText={t('crud_actions:delete')}
        description=""
        title={t('want_delete')}
      />
    </Box>
  );
}

export default ChannelGroupList;

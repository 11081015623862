import React, { useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useYupObject } from 'hooks';
import { channelGroupInEditionAtom } from 'atoms/channelGroup';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFormik } from 'formik';
import { Form } from 'components/core';
import { ChannelGroup } from 'types/channelGroup.types';
import { useChannelGroupApi } from 'hooks/channelGroup/useChannelGroupApi';
import SelectLicenseType from 'components/SelectLicenseType';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { License } from 'types/license.types';
import { loggedUserAtom } from 'atoms/users';
import { useFormStyles } from '../core/Form';

function ChannelGroupForm({
  setOpen,
  channelGroupInEdition,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  channelGroupInEdition: ChannelGroup | null;
}) {
  const { t } = useTranslation(['channel_groups', 'crud_actions']);
  const [licenseTypeFlag] = useDecision(FEATURE_FLAGS_KEYS.MODELOS_DE_LICENCA);
  const loggedUser = useRecoilValue(loggedUserAtom);
  const { createChannelGroup, editChannelGroup } = useChannelGroupApi();
  const setChannelGroupInEdition = useSetRecoilState(channelGroupInEditionAtom);
  const [licenseSelected, setLicenseSelected] = React.useState<License | null>(null);

  const classesForm = useFormStyles({ dialog: true });
  const yup = useYupObject();
  const validationSchema = yup.object({
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    licenseId: yup.number().nullable(),
  });

  const formik = useFormik({
    initialValues: channelGroupInEdition || { id: null, name: '', licenseId: null, quantity: null },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { name, licenseId, id } = values;

        const valuesTransformed = {
          id,
          name: name.trim(),
          licenseId: Number(licenseId) || null,
        };

        if (channelGroupInEdition) {
          await editChannelGroup(
            valuesTransformed as ChannelGroup,
            channelGroupInEdition.licenseId
          );
        } else {
          await createChannelGroup(valuesTransformed as ChannelGroup);
        }
        setSubmitting(false);
        setOpen(false);
        setTimeout(() => setChannelGroupInEdition(null), 150);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const [hasLicenseError, setHasLicenseError] = React.useState(false);
  const [helperTextLicenseError, setHelperTextLicenseError] = React.useState<string | null>(null);

  useEffect(
    function handleWithChannelsAvailable() {
      if (licenseSelected) {
        const totalLicenseAvailableChannels =
          licenseSelected.maxAllowedChannels - licenseSelected.consumedChannels;
        const totalChannelsInGroup = formik.values.quantity ?? 0;

        if (
          typeof totalLicenseAvailableChannels === 'number' &&
          totalLicenseAvailableChannels <= 0
        ) {
          setHelperTextLicenseError(t('license_models:no_channels_available'));
          setHasLicenseError(true);
          return;
        }

        if (totalLicenseAvailableChannels < totalChannelsInGroup) {
          setHelperTextLicenseError(t('license_models:exceeded_license_limit'));
          setHasLicenseError(true);
          return;
        }

        setHasLicenseError(false);
        setHelperTextLicenseError('');
      }

      if (channelGroupInEdition?.licenseId && !licenseSelected) {
        setLicenseSelected({
          id: channelGroupInEdition.licenseId,
        } as License);
      }
    },

    [formik.values.quantity, licenseSelected, channelGroupInEdition?.licenseId, t]
  );
  return (
    <form
      key={channelGroupInEdition?.id}
      aria-label="ChannelGroupCreate"
      className={classesForm.form}
      onSubmit={formik.handleSubmit}
    >
      <Box className={classesForm.formInputsContainer}>
        <TextField
          sx={{ marginTop: '5px' }}
          fullWidth
          label={t('name')}
          name="name"
          id="name"
          autoFocus
          placeholder={t('enter_channel_group_name')}
          helperText={
            (formik.touched.name && formik.errors.name) || t('forms:string_max', { max: 100 })
          }
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
      </Box>
      {licenseTypeFlag.enabled && loggedUser?.masterCompany.enableLicense && (
        <Box className={classesForm.formInputsContainer}>
          <SelectLicenseType
            selectedLicenseId={licenseSelected?.id}
            setSelectedLicense={setLicenseSelected}
            onChange={formik.handleChange}
            hasError={hasLicenseError}
            helperText={(hasLicenseError && helperTextLicenseError) || ''}
          />
        </Box>
      )}
      <Box className={classesForm.formFooter}>
        <Form.CancelButton
          isSubmitting={formik.isSubmitting}
          onClick={() => {
            setOpen(false);
            setTimeout(() => setChannelGroupInEdition(null), 150);
          }}
        />
        <Form.SaveButton
          isSubmitting={formik.isSubmitting}
          initialValues={formik.initialValues}
          dialog
          label={channelGroupInEdition ? t('crud_actions:save') : t('crud_actions:create')}
          values={formik.values}
          disabled={!formik.dirty || hasLicenseError}
        />
      </Box>
    </form>
  );
}
export default ChannelGroupForm;

import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DebounceInput from 'components/core/DebounceInput';
import { useRecoilState } from 'recoil';
import { licenseModelSearchTextAtom } from 'atoms/license';

export default function SearchLicenseModelsBar() {
  const { t } = useTranslation('license_models');
  const inputRef = useRef<HTMLInputElement>();
  const [searchTextDebounced, setSearchTextDebounced] = useRecoilState(licenseModelSearchTextAtom);
  const [searchText, setSearchText] = React.useState(searchTextDebounced);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('_common:licenses')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
          width: '500px',
        }}
      >
        <DebounceInput
          {...{ inputRef }}
          placeholder={t('crud_actions:search')}
          autoFocus
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          setValue={setSearchText}
          onDebounce={() => setSearchTextDebounced(searchText)}
        />
      </Box>
    </>
  );
}

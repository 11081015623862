import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLicenseModels } from 'hooks/useLicenses/useLicenses';
import { useYupObject } from 'hooks';
import { useFormik } from 'formik';

export default function LicenseNameFormDialog({
  open,
  onClose,
  license,
}: {
  open: boolean;
  onClose: () => void;
  license: {
    id: number;
    name: string;
  };
}) {
  const { t } = useTranslation();
  const { updateLicenseName } = useLicenseModels();
  const [loading, setLoading] = React.useState(false);

  const yup = useYupObject();

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(5)
      .max(100)
      .required()
      .noSpecialCharacters()
      .matches(/^[a-zA-Zç][a-zA-Z0-9.,$;ç @ -]+$/, `${t('license_model:start_with_letter')}`),
  });

  const handleSave = () => {
    setLoading(true);
    updateLicenseName(formik.values);
    onClose();
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: license,
    validationSchema,
    onSubmit: handleSave,
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      fullWidth
    >
      <DialogTitle>{t('license_models:update_license_name')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              marginTop: '1.2rem',
            }}
          >
            <TextField
              autoComplete="off"
              fullWidth
              id="name"
              name="name"
              label={t('_common:name')}
              helperText={formik.errors.name || ''}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          onClick={() => {
            formik.resetForm();
            onClose();
          }}
        >
          {t('_common:cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading || !formik.dirty || !formik.isValid}
        >
          {t('crud_actions:save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
